const queryKeys = {
  client: {
    appUI: 'client_app_ui',
    logger: 'client_logger',
    auth: 'client_auth',
    // user: 'client_user',
    // wizard: 'client_wizard'
  },
  server: {
    appSetting: 'server_app_setting',
    userProfile: 'server_user_profile',
    userSetting: 'server_user_setting',
    menuMgt: 'server_menumgt',
    customerInfo: 'server_customer_info',
    appContent: 'server_app_content',
    subscriptionPlan: 'server_subscription_plan',
    userSettingList: 'server_user_setting_list',
    userLastSub: 'server_user_last_subscription',
  }
};

export default queryKeys;