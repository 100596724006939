import {
  useQuery, useQueryClient, useMutation
} from '@tanstack/react-query';
import type { QueryClient } from '@tanstack/react-query';
import { helper } from '../lib/helper';
import queryKeys from './queryKeys';
import * as Api from './api';
import jwt_decode from "jwt-decode";

const DefaultUIState = {
  lastError: '',
  busy: false,
  lang: 'en-US',
  displayMode: 'auto',
  siteInfo: {
    supportedLanguages: [],
    defaultLanguage: 'en-US',
    currentLogicalPath: '/'
  }
};

const DefaultAuthState = {
  access_token: null,
  expires: null,
  refresh_token: null,
  expires_at: null,
  id: null,
  role: null,
  app_access: 0,
  admin_access: 0,
  iat: null,
  exp: null,
  iss: "",
  rememberMe: false, 
  heartBeat: new Date()
};

const DefaultLoggerState = {
  logs: [],
  last_sync_on: null,
  last_sync_status: '',
  login_user: null,
  max_log_cap: 100 // Default to 100. The max log capacity the browser can hold. This is to prevent when the server is down, the client browser keep generating the log. This will overload the server when it is back online.
};



class ClientState {
  
  /**
   * Get the application initial state
   * @param {boolean} reset - If true is specify, it will ignore the state stored in storage.
   */
  static useAppUIGet(reset = false) {
    return useQuery({
      queryKey: [queryKeys.client.appUI],
      queryFn: async () => {
        const savedStateString = localStorage.getItem(queryKeys.client.appUI);
        const state = savedStateString !== null && savedStateString !== undefined && !reset ? JSON.parse(savedStateString) : DefaultUIState;
        return(state);
      }
    });  
  }

  static useAppUISet(queryClient : QueryClient) {
    
    return useMutation({
      // mutationKey: queryKeys.client.appUI,
      mutationFn: newData => (
        new Promise((resolve, reject) => {
          try {
            localStorage.setItem(queryKeys.client.appUI, helper.isNotNullAndUndefined(newData) ? JSON.stringify(newData) : '');
            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
      ),
      onSuccess: () => {
        // Invalidate and refetch
        return queryClient.invalidateQueries({ queryKey: [queryKeys.client.appUI] });
      },
    });
  }

  static useLoggerGet() {
    return useQuery({
      queryKey: [queryKeys.client.logger],
      queryFn: async () => {
        const savedStateString = localStorage.getItem(queryKeys.client.logger);
        const state = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
  
        if(helper.isNotNullAndUndefined(state.last_sync_on)) {
          if(typeof state.last_sync_on === 'string') {
            state.last_sync_on = new Date(state.last_sync_on);
          }
        }
        return(state);
      }
    });  
  }

  static useLoggerInsert(queryClient : QueryClient, consoleLogEnabled: boolean=false) {
    
    return useMutation({
      mutationFn: (newData : any) => (
        new Promise((resolve, reject) => {
          try {
            const savedStateString = localStorage.getItem(queryKeys.client.logger);
            const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
  
            // In case existingState.logs is null or undefined, initalise it to empty array
            if(!helper.isNotNullAndUndefined(existingState.logs)) {
              existingState.logs = [];
            }

            // Only insert the log if it not exceed the max_log_cap.
            if(existingState.logs.length < existingState.max_log_cap) {
              newData.user = existingState.login_user;
              existingState.logs.push(newData);
            }

            localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));

            consoleLogEnabled && console.log('App Logger: ', newData);
            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
      ),
      onSuccess: () => {
        // Invalidate and refetch
        return queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });
      },
    });
  }

  static useLoggerSync(queryClient : QueryClient) {
    
    return useMutation({
      mutationFn: (option : any) => (
        new Promise(async (resolve, reject) => {
          try {
            const savedStateString = localStorage.getItem(queryKeys.client.logger);
            const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
  
            // In case existingState.logs is null or undefined, initalise it to empty array
            if(!helper.isNotNullAndUndefined(existingState.logs)) {
              existingState.logs = [];
            }

            // Sync the log to the server, if any.
            if(existingState.logs.length > 0) {
              
              let batchSize = option?.batchSize | 10;
              let logToSync = existingState.logs.splice(0, batchSize);
              
              try {
                await Api.syncLog(logToSync);
                existingState.last_sync_on = new Date();
                existingState.last_sync_status = 'success';
              } catch (error) {
                console.log('Sync Log Error: ', error);
                existingState.last_sync_on = new Date();
                existingState.last_sync_status = 'failed';
                // If unable to sync, place back the spliced array items
                existingState.logs = logToSync.concat(existingState.logs);
              }
              
              localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));
            }

            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
      ),
      onSuccess: () => {
        // Invalidate and refetch
        return queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });
      },
    });
  }

  static async userAuthenticate(credential : any, queryClient : QueryClient) : Promise<any> {
    
    try {
      let authResult = await Api.authenticate(credential.email, credential.password, credential.otp);
      
      var decoded : Object = helper.stringHasValue(authResult.access_token) ? jwt_decode(authResult.access_token as string) : {};
      let authState : any = {
        ...authResult,
        ...decoded,
        rememberMe: credential.rememberMe, 
        heartBeat: new Date()
      };
      localStorage.setItem(queryKeys.client.auth, JSON.stringify(authState));

      //#region Read the user profile
      let userProfile = await Api.userProfile();
      
      if(helper.isNotNullAndUndefined(userProfile)) {
        authState.user = {...userProfile};
      }
      //#endregion

      //#region Update the logger state
      const savedStateString = localStorage.getItem(queryKeys.client.logger);
      const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
      existingState.login_user = authState.id;

      // In case existingState.logs is null or undefined, initalise it to empty array
      if(!helper.isNotNullAndUndefined(existingState.logs)) {
        existingState.logs = [];
      }

      localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));
      //#endregion

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.auth] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });

      return authState;
    } catch (error) {
      return error;
    }
    
  }

  static async userAuthenticateViaOTP(credential : any, queryClient : QueryClient) : Promise<any> {
    
    try {
      localStorage.setItem(queryKeys.client.auth, '{}');
      let authResult : any = await Api.authViaOtp(credential.email, credential.otp, credential.purpose);
      // console.log('userAuthenticateViaOTP: ', authResult);

      var decoded : Object = helper.stringHasValue(authResult.access_token) ? jwt_decode(authResult.access_token as string) : {};
      let authState : any = {
        ...authResult,
        ...decoded,
        rememberMe: credential.rememberMe, 
        heartBeat: new Date()
      };
      localStorage.setItem(queryKeys.client.auth, JSON.stringify(authState));

      //#region Read the user profile
      let userProfile = await Api.userProfile();
      
      if(helper.isNotNullAndUndefined(userProfile)) {
        authState.user = {...userProfile};
      }
      //#endregion

      //#region Update the logger state
      const savedStateString = localStorage.getItem(queryKeys.client.logger);
      const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
      existingState.login_user = authState.id;

      // In case existingState.logs is null or undefined, initalise it to empty array
      if(!helper.isNotNullAndUndefined(existingState.logs)) {
        existingState.logs = [];
      }

      localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));
      //#endregion

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.auth] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });

      return authState;
    } catch (error) {
      return error;
    }
    
  }

  static async userSessionRefresh(queryClient : QueryClient) : Promise<any> {
    
    try {
      const savedAuthStateString = localStorage.getItem(queryKeys.client.auth);
      const authState = savedAuthStateString !== null && savedAuthStateString !== undefined ? JSON.parse(savedAuthStateString) : DefaultAuthState;
  
      let authResult = await Api.refresh();
      
      var decoded : Object = helper.stringHasValue(authResult.access_token) ? jwt_decode(authResult.access_token as string) : {};
      let refreshedAuthState : any = {
        ...authResult,
        ...decoded,
        rememberMe: authState.rememberMe, 
        heartBeat: new Date()
      };
      localStorage.setItem(queryKeys.client.auth, JSON.stringify(refreshedAuthState));

      //#region Read the user profile
      let userProfile = await Api.userProfile();
      
      if(helper.isNotNullAndUndefined(userProfile)) {
        refreshedAuthState.user = {...userProfile};
      }
      //#endregion

      //#region Update the logger state
      const savedStateString = localStorage.getItem(queryKeys.client.logger);
      const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
      existingState.login_user = authState.id;

      // In case existingState.logs is null or undefined, initalise it to empty array
      if(!helper.isNotNullAndUndefined(existingState.logs)) {
        existingState.logs = [];
      }

      localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));
      //#endregion

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.auth] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });

      return refreshedAuthState;
    } catch (error) {
      return error;
    }
    
  }

  static async userSessionLogout(queryClient : QueryClient) : Promise<any> {
    
    try {
      const savedAuthStateString = localStorage.getItem(queryKeys.client.auth);
      const authState = savedAuthStateString !== null && savedAuthStateString !== undefined ? JSON.parse(savedAuthStateString) : DefaultAuthState;
  
      await Api.logout();
      
      localStorage.setItem(queryKeys.client.auth, JSON.stringify(DefaultAuthState));

      //#region Update the logger state
      const savedStateString = localStorage.getItem(queryKeys.client.logger);
      const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultLoggerState;
      existingState.login_user = null;

      // In case existingState.logs is null or undefined, initalise it to empty array
      if(!helper.isNotNullAndUndefined(existingState.logs)) {
        existingState.logs = [];
      }

      localStorage.setItem(queryKeys.client.logger, JSON.stringify(existingState));
      //#endregion

      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.auth] });
      queryClient.invalidateQueries({ queryKey: [queryKeys.client.logger] });

      return null;
    } catch (error) {
      return error;
    }
    
  }

  static useAuthInfoGet() {
    return useQuery({
      queryKey: [queryKeys.client.auth],
      queryFn: async () => {
        const savedStateString = localStorage.getItem(queryKeys.client.auth);
        const state = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultAuthState;
        
        if(helper.isNotNullAndUndefined(state.heartBeat)) {
          if(typeof state.heartBeat === 'string') {
            state.heartBeat = new Date(state.heartBeat);
          }
        }

        return(state);
      }
    });  
  }

  static useAuthHeartbeatSet(queryClient : QueryClient) {
    
    return useMutation({
      mutationFn: () => (
        new Promise((resolve, reject) => {
          try {
            const savedStateString = localStorage.getItem(queryKeys.client.auth);
            const existingState = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : DefaultAuthState;
  
            existingState.heartBeat = new Date();

            localStorage.setItem(queryKeys.client.auth, JSON.stringify(existingState));

            resolve(null);
          } catch (error) {
            reject(error);
          }
        })
      ),
      onSuccess: () => {
        // Invalidate and refetch
        return queryClient.invalidateQueries({ queryKey: [queryKeys.client.auth] });
      },
    });
  }

}

export default ClientState;