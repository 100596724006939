/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/how-to/custom-configuration/typescript/#gatsby-browsertsx--gatsby-ssrtsx:~:text=PageProps-,gatsby%2Dbrowser.tsx%20/%20gatsby%2Dssr.tsx,-getServerData
 */

import * as React from "react"
import type { GatsbyBrowser } from "gatsby";
import ThemeWrapper from './theme-wrapper';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
// import LocomotiveScroll from 'locomotive-scroll';

const queryClient = new QueryClient();

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({ element }) => {
  // const scroll = new LocomotiveScroll({
  //   el: document.querySelector('[data-scroll-container]'),
  //   smooth: true
  // });

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeWrapper>
        {element}
      </ThemeWrapper>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}