import React from 'react';
import { useMediaQuery, CssBaseline, createTheme, ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { green, blue, grey, orange } from '@mui/material/colors';
import ClientState from "./src/api/client";
import UIHelper from './src/lib/uiHelper';
import './layout.css';
import './app.css';
// import './locomotive-scroll.css';
import './node_modules/locomotive-scroll/bundled/locomotive-scroll.css';

const ThemeContainer = (props) => {
  const appUI = ClientState.useAppUIGet();
  // const {displayModeToUse} = UIHelper.getDisplayModeSetting(useMediaQuery('(prefers-color-scheme: dark)'), appUI);
  // Force dark mode
  const displayModeToUse = 'dark';
  
  const theme = createTheme({
    palette: {
      mode: displayModeToUse as any,
      primary: {
        main: displayModeToUse === 'dark' ? green[700] : blue[500],
      },
      secondary: {
        main: displayModeToUse === 'dark' ? orange[300] : orange[900],
      },
      background: {
        default: displayModeToUse === 'dark' ? grey[900] : grey[200],
        paper: displayModeToUse === 'dark' ? grey[800] : grey[300],
      }
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider 
        dateAdapter={AdapterMoment}
        
      >
        <CssBaseline/>
        {props.children}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default ThemeContainer;