exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-content-account-tsx": () => import("./../../../src/pages/content/account.tsx" /* webpackChunkName: "component---src-pages-content-account-tsx" */),
  "component---src-pages-content-index-tsx": () => import("./../../../src/pages/content/index.tsx" /* webpackChunkName: "component---src-pages-content-index-tsx" */),
  "component---src-pages-content-policy-tsx": () => import("./../../../src/pages/content/policy.tsx" /* webpackChunkName: "component---src-pages-content-policy-tsx" */),
  "component---src-pages-content-refund-tsx": () => import("./../../../src/pages/content/refund.tsx" /* webpackChunkName: "component---src-pages-content-refund-tsx" */),
  "component---src-pages-content-terms-tsx": () => import("./../../../src/pages/content/terms.tsx" /* webpackChunkName: "component---src-pages-content-terms-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-subresult-tsx": () => import("./../../../src/pages/subresult.tsx" /* webpackChunkName: "component---src-pages-subresult-tsx" */),
  "component---src-pages-subscription-tsx": () => import("./../../../src/pages/subscription.tsx" /* webpackChunkName: "component---src-pages-subscription-tsx" */)
}

