import { 
  browserName, fullBrowserVersion, osName, osVersion, getUA
} from 'react-device-detect';

export const helper = {
  
  getErrorMsg(err : any, withStackTrace=false) : string {
    let errMsg = '';
    
    if(err) {
      if(typeof err === 'string') {
        errMsg = err;
      }

      if(typeof err === 'object') {
        if(err.message) {
          errMsg = err.message;
          if(withStackTrace && err.stack) {
            errMsg = errMsg + '\n' + err.stack
          }
        }

        if(errMsg.length <= 0 && this.arrayHasItem(err.errors)) {
          errMsg = err.errors[0].message;
          if(withStackTrace && this.isNotNullAndUndefined(err.errors[0].extensions)) {
            errMsg = errMsg + '\n' + JSON.stringify(err.errors[0].extensions);
          }
        }
      }
    }
    return errMsg;
  },

  getStackTrace(err : any) : string {
    let stactTrace = '';
    
    if(err) {
      if(typeof err === 'string') {
        stactTrace = err;
      }

      if(typeof err === 'object') {
        if(err.stack) {
          stactTrace = err.stack;
        }

        if(stactTrace.length <= 0 && this.arrayHasItem(err.errors)) {
          if(this.isNotNullAndUndefined(err.errors[0].extensions)) {
            stactTrace = JSON.stringify(err.errors[0].extensions);
          }
        }
      }
    }
    return stactTrace;
  },

  getAxiosError(res : any) : any {
    let errObj = {
      statusCode: 0,
      error: '',
      message: ''
    };
    let isFilled = false;

    if(res && res.response && res.response.status !== 200) {
      if(res.response.data) {
        errObj = res.response.data;
        isFilled = true;
      }

      if(!isFilled && res.response.errors && this.arrayHasItem(res.response.errors)) {
        errObj.statusCode = res.response.status;
        errObj.error = res.response.errors[0].extensions.code;
        errObj.message = res.response.errors[0].message;
        isFilled = true;
      }

      if(!isFilled) {
        errObj.statusCode = res.response.status;
        errObj.error = 'ERROR';
        errObj.message = this.stringHasValue(res.message) ? res.message : res.response.statusText;
      }
    }
    return errObj;
  },

  getDirectusErrorMsg: function(err : any, withStackTrace=false) {
    let errMsg = '';

    if(err) {
      if(typeof err === 'string') {
        errMsg = err;
      }

      if(typeof err === 'object') {
        if(this.isNotNullAndUndefined(err.errors) && this.arrayHasItem(err.errors)) {
          if(this.stringHasValue(err.errors[0].extensions?.message)) {
            errMsg = err.errors[0].extensions?.code + ' - ' + err.errors[0].extensions?.message;
          }
          else {
            errMsg = err.errors[0].message;
          }
        }
        else {
          if(err.message)
            errMsg = err.message;
          else
            errMsg = err.response?.statusText;
        }
      }
    }
    return errMsg;
  },

  deepCopy(obj : any) : any {
    var copy : any;
  
    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;
  
    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }
  
    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
          copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }
  
    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) 
          copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }
  
    throw new Error("Unable to copy obj! Its type isn't supported.");
  },

  isNotNullAndUndefined(obj : Object | null | void, props: string[] = []) : obj is Object {
    let bIsNullorUndefined =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    return !bIsNullorUndefined;
  },

  stringHasValue(str : string | null | undefined) : str is string {
    if(str === null || str === undefined || typeof str !== 'string') {
      return false;
    }
    else {
      return str.trim().length > 0;
    }
  },

  arrayHasItem(arr: any[]) : arr is any[] {
    return arr && Array.isArray(arr) && arr.length > 0;
  },

  padding(input : string, totalChar : number, padWith : string ='0', padInFront : boolean=true) : string { 
    var result = input;
    if (totalChar > input.length) { 
      for (let i=0; i < (totalChar - input.length); i++) 
      { 
        if(padInFront)
          result = padWith + result; 
        else
          result = result + padWith; 
      } 
    } 
    return result;
  },

  carefullyGetStringValue(obj : Object | null, props : string[] = [], defaultValue : string='', placeholder='{0}') : string {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return placeholder.replace('{0}', defaultValue);
    else
      return placeholder.replace('{0}', curObj.toString());
  },

  carefullyGetIntValue(obj : Object | null, props : string[] = [], defaultValue : number=0) : number {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  carefullyGetBooleanValue(obj : Object | null, props : string[] = [], defaultValue : boolean=false) : boolean {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  carefullyGetArrayValue(obj : Object | null, props : string[] = [], defaultValue : any[]=[]) : any[] {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  carefullyGetObjectValue(obj : Object | null, props : string[] = [], defaultValue : Object | null=null) : Object | null {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  carefullyGetDateValue(obj : Object | null, props : string[] = [], defaultValue : Date | null=null) : Object | null {
    let bIsNullorUndefined : boolean =  obj === null || obj === undefined;
    let curObj : any = null;

    if(!bIsNullorUndefined) {
      curObj = obj;
      if(props !== null) {
        for(let idx=0; idx < props.length; idx++) {
          bIsNullorUndefined = curObj[props[idx]] === null || curObj[props[idx]] === undefined;
          curObj =  curObj[props[idx]]; // Set the curObj[props[idx]] to curObj so that it will recursive down the depth of the object

          if(bIsNullorUndefined)
            break;
        }
      }

      if(!bIsNullorUndefined && typeof curObj === 'string') {
        if(curObj[curObj.length - 1] !== 'Z') {
          curObj += 'Z';
        }
        curObj = new Date(curObj);
      }
    }
    
    if(bIsNullorUndefined)
      return defaultValue;
    else
      return curObj;
  },

  onFormFieldChanged: function(field : string, value : string, frmData : any, setState : any) {
    let form = this.isNotNullAndUndefined(frmData) ? this.deepCopy(frmData) : {};
    form[field] = value;
    setState(form);
  },

  validateFormField: function(field : string, value : string, formFields : any[], formValidation : any, setState : any, t : (key : string) => string) {
    let formField = formFields.find((item : any) => item.field === field);
    let localFormValidation = this.isNotNullAndUndefined(formValidation) ? this.deepCopy(formValidation) : {};
    let formFieldValidate = this.isNotNullAndUndefined(localFormValidation[field]) ? localFormValidation[field] : {error: false, touched: false, message: '', skipValidate: false};
    formFieldValidate.error = false;
    formFieldValidate.message = '';
    
    if(this.isNotNullAndUndefined(formField)) {
      formFieldValidate.touched = true;

      // If required, check if have value
      if(formField.required && !this.stringHasValue(value)) {
        // No value, set error to true and set message
        formFieldValidate.error = true;
        formFieldValidate.message = t('error.message.fieldRequired').replace('{0}', t(formField.label));
      }

      // If field have value and regex is not empty, check the regex
      if(this.stringHasValue(formField.regExp) && this.stringHasValue(value)) {
        let regExp = RegExp(formField.regExp);
        if(!regExp.test(value)) {
          formFieldValidate.error = true;
          formFieldValidate.message = t('error.message.fieldInvalid').replace('{0}', t(formField.label));
        }
      }
      
      
      localFormValidation[field] = formFieldValidate;
      setState(localFormValidation);
    }
  },

  validateForm: function(frmData : any, formFields : any[], formValidation : any, setState : any, t : (key : string) => string, customValidator : any=null) {
    // let fields = Helper.arrayHasItem(fieldsToValidate) ? formFields.filter(item => fieldsToValidate.includes(item.field)) : formFields;
    let validation = this.deepCopy(formValidation);
    let formIsValid = true;

    formFields.forEach((item : any) => {
      validation[item.field].error = false;
      validation[item.field].message = '';

      // If required, check if have value
      if(item.required && !this.stringHasValue(frmData[item.field])) {
        // No value, set error to true and set message
        validation[item.field].error = true;
        validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
        formIsValid = false;
      }
      
      // If field have value and regex is not empty, check the regex
      if(this.stringHasValue(item.regExp) && this.stringHasValue(frmData[item.field])) {
        let regExp = RegExp(item.regExp);
        if(!regExp.test(frmData[item.field])) {
          validation[item.field].error = true;
          validation[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
          formIsValid = false;
        }
      }
    });

    // If all fields is valid, and customValidator is defined, invoke the customValidator.
    if(formIsValid && customValidator) {
      formIsValid = customValidator();
    }

    setState(validation);
    return formIsValid;
  },

  validateFormRef: function(frmDataRef : any, formFields : any[] , formValidation : any, setState : any, t : (key : string) => string, customValidator : any=null) {
    // let fields = Helper.arrayHasItem(fieldsToValidate) ? formFields.filter(item => fieldsToValidate.includes(item.field)) : formFields;
    let validation = this.deepCopy(formValidation);
    let formIsValid = true;

    formFields.forEach(item => {
      validation[item.field].error = false;
      validation[item.field].message = '';

      if(!validation[item.field].skipValidate) {
        // If required, check if have value
        if(item.required && !this.stringHasValue(frmDataRef.current?.elements[item.field]?.value)) {
          // No value, set error to true and set message
          validation[item.field].error = true;
          validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
          formIsValid = false;
        }

        // Special logic to check date
        if(item.type === 'date' && item.required && this.stringHasValue(frmDataRef.current?.elements[item.field]?.value)) {
          let dateParts = frmDataRef.current?.elements[item.field]?.value.split('/');
          for(let idx=0; idx<dateParts.length; idx++) {
            if(!this.isInteger(dateParts[idx])) {
              validation[item.field].error = true;
              validation[item.field].message = t('error.message.fieldRequired').replace('{0}', t(item.label));
              formIsValid = false;
              break;
            }
          };
        }
        
        // If field have value and regex is not empty, check the regex
        if(this.stringHasValue(item.regExp) && this.stringHasValue(frmDataRef.current?.elements[item.field]?.value)) {
          let regExp = RegExp(item.regExp);
          if(!regExp.test(frmDataRef.current?.elements[item.field]?.value)) {
            validation[item.field].error = true;
            validation[item.field].message = t('error.message.fieldInvalid').replace('{0}', t(item.label));
            formIsValid = false;
          }
        }
      }
    });

    // If all fields is valid, and customValidator is defined, invoke the customValidator.
    if(formIsValid && customValidator) {
      formIsValid = customValidator({frmDataRef, formFields, validation, setState, t});
    }

    setState(validation);
    return formIsValid;
  },

  fieldHasError: function(field : string, value : any, fields : any[]) {
    let hasError = false;
    
    // If no fields definition, return error.
    if(!helper.arrayHasItem(fields)) {
      return true;
    }

    let fieldDef = fields.find(item => item.field === field);

    // If fields definition not found, return error
    if(!helper.isNotNullAndUndefined(fieldDef)) {
      return true;
    }

    // If required, check if have value
    if(fieldDef.required && ((fieldDef.type === 'string' && !this.stringHasValue(value)) || (fieldDef.type !== 'string' && !this.isNotNullAndUndefined(value)))) {
      // No value, set error to true and set message
      return true
    }

    // Special logic to check date
    if(fieldDef.type === 'date' && fieldDef.required && this.stringHasValue(value)) {
      let dateParts = value.split('/');
      for(let idx=0; idx<dateParts.length; idx++) {
        if(!this.isInteger(dateParts[idx])) {
          return true;
        }
      };
    }
    
    // If field have value and regex is not empty, check the regex
    if(this.stringHasValue(fieldDef.regExp) && this.stringHasValue(value)) {
      let regExp = RegExp(fieldDef.regExp);
      if(!regExp.test(value)) {
        return true;
      }
    }

    return false;
  },

  isInteger(value : string) {
    return /^[-+]?[0-9]+$/.test(value);
  },

  isNumeric(value : any) {
    return /^[-+]?\d*\.?\d*$/.test(value);
  },

  isCurrency(value : any) {
    return /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/.test(value);
  },

  isPositiveCurrency(value : any) {
    return /^[+]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/.test(value);
  },

  isNegativeCurrency(value : any) {
    return /^[-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/.test(value);
  },

  textToHtml(plainText : string, includeContainer : boolean=true, containerTag : string='p') {
    let htmlText = plainText.replace(/"/g,'&quot;'); // 34 22
    htmlText = htmlText.replace(/&/g,'&amp;'); // 38 26
    htmlText = htmlText.replace(/'/g,'&#39;'); // 39 27
    htmlText = htmlText.replace(/</g,'&lt;'); // 60 3C
    htmlText = htmlText.replace(/>/g,'&gt;'); // 62 3E
    htmlText = htmlText.replace(/\^/g,'&circ;'); // 94 5E
    htmlText = htmlText.replace(/‘/g,'&lsquo;'); // 145 91
    htmlText = htmlText.replace(/’/g,'&rsquo;'); // 146 92
    htmlText = htmlText.replace(/“/g,'&ldquo;'); // 147 93
    htmlText = htmlText.replace(/”/g,'&rdquo;'); // 148 94
    htmlText = htmlText.replace(/•/g,'&bull;'); // 149 95
    htmlText = htmlText.replace(/–/g,'&ndash;'); // 150 96
    htmlText = htmlText.replace(/—/g,'&mdash;'); // 151 97
    htmlText = htmlText.replace(/˜/g,'&tilde;'); // 152 98
    htmlText = htmlText.replace(/™/g,'&trade;'); // 153 99
    htmlText = htmlText.replace(/š/g,'&scaron;'); // 154 9A
    htmlText = htmlText.replace(/›/g,'&rsaquo;'); // 155 9B
    htmlText = htmlText.replace(/œ/g,'&oelig;'); // 156 9C
    htmlText = htmlText.replace(/ž/g,'&#382;'); // 158 9E
    htmlText = htmlText.replace(/Ÿ/g,'&Yuml;'); // 159 9F
    htmlText = htmlText.replace(/ /g,'&nbsp;'); // 160 A0
    htmlText = htmlText.replace(/¡/g,'&iexcl;'); // 161 A1
    htmlText = htmlText.replace(/¢/g,'&cent;'); // 162 A2
    htmlText = htmlText.replace(/£/g,'&pound;'); // 163 A3
    htmlText = htmlText.replace(/¥/g,'&yen;'); // 165 A5
    htmlText = htmlText.replace(/¦/g,'&brvbar;'); // 166 A6
    htmlText = htmlText.replace(/§/g,'&sect;'); // 167 A7
    htmlText = htmlText.replace(/¨/g,'&uml;'); // 168 A8
    htmlText = htmlText.replace(/©/g,'&copy;'); // 169 A9
    htmlText = htmlText.replace(/ª/g,'&ordf;'); // 170 AA
    htmlText = htmlText.replace(/«/g,'&laquo;'); // 171 AB
    htmlText = htmlText.replace(/¬/g,'&not;'); // 172 AC
    htmlText = htmlText.replace(/­/g,'&shy;'); // 173 AD
    htmlText = htmlText.replace(/®/g,'&reg;'); // 174 AE
    htmlText = htmlText.replace(/¯/g,'&macr;'); // 175 AF
    htmlText = htmlText.replace(/°/g,'&deg;'); // 176 B0
    htmlText = htmlText.replace(/±/g,'&plusmn;'); // 177 B1
    htmlText = htmlText.replace(/²/g,'&sup2;'); // 178 B2
    htmlText = htmlText.replace(/³/g,'&sup3;'); // 179 B3
    htmlText = htmlText.replace(/´/g,'&acute;'); // 180 B4
    htmlText = htmlText.replace(/µ/g,'&micro;'); // 181 B5
    htmlText = htmlText.replace(/¶/g,'&para'); // 182 B6
    htmlText = htmlText.replace(/·/g,'&middot;'); // 183 B7
    htmlText = htmlText.replace(/¸/g,'&cedil;'); // 184 B8
    htmlText = htmlText.replace(/¹/g,'&sup1;'); // 185 B9
    htmlText = htmlText.replace(/º/g,'&ordm;'); // 186 BA
    htmlText = htmlText.replace(/»/g,'&raquo;'); // 187 BB
    htmlText = htmlText.replace(/¼/g,'&frac14;'); // 188 BC
    htmlText = htmlText.replace(/½/g,'&frac12;'); // 189 BD
    htmlText = htmlText.replace(/¾/g,'&frac34;'); // 190 BE
    htmlText = htmlText.replace(/¿/g,'&iquest;'); // 191 BF
    htmlText = htmlText.replace(/À/g,'&Agrave;'); // 192 C0
    htmlText = htmlText.replace(/Á/g,'&Aacute;'); // 193 C1
    htmlText = htmlText.replace(/Â/g,'&Acirc;'); // 194 C2
    htmlText = htmlText.replace(/Ã/g,'&Atilde;'); // 195 C3
    htmlText = htmlText.replace(/Ä/g,'&Auml;'); // 196 C4
    htmlText = htmlText.replace(/Å/g,'&Aring;'); // 197 C5
    htmlText = htmlText.replace(/Æ/g,'&AElig;'); // 198 C6
    htmlText = htmlText.replace(/Ç/g,'&Ccedil;'); // 199 C7
    htmlText = htmlText.replace(/È/g,'&Egrave;'); // 200 C8
    htmlText = htmlText.replace(/É/g,'&Eacute;'); // 201 C9
    htmlText = htmlText.replace(/Ê/g,'&Ecirc;'); // 202 CA
    htmlText = htmlText.replace(/Ë/g,'&Euml;'); // 203 CB
    htmlText = htmlText.replace(/Ì/g,'&Igrave;'); // 204 CC
    htmlText = htmlText.replace(/Í/g,'&Iacute;'); // 205 CD
    htmlText = htmlText.replace(/Î/g,'&Icirc;'); // 206 CE
    htmlText = htmlText.replace(/Ï/g,'&Iuml;'); // 207 CF
    htmlText = htmlText.replace(/Ð/g,'&ETH;'); // 208 D0
    htmlText = htmlText.replace(/Ñ/g,'&Ntilde;'); // 209 D1
    htmlText = htmlText.replace(/Ò/g,'&Ograve;'); // 210 D2
    htmlText = htmlText.replace(/Ó/g,'&Oacute;'); // 211 D3
    htmlText = htmlText.replace(/Ô/g,'&Ocirc;'); // 212 D4
    htmlText = htmlText.replace(/Õ/g,'&Otilde;'); // 213 D5
    htmlText = htmlText.replace(/Ö/g,'&Ouml;'); // 214 D6
    htmlText = htmlText.replace(/×/g,'&times;'); // 215 D7
    htmlText = htmlText.replace(/Ø/g,'&Oslash;'); // 216 D8
    htmlText = htmlText.replace(/Ù/g,'&Ugrave;'); // 217 D9
    htmlText = htmlText.replace(/Ú/g,'&Uacute;'); // 218 DA
    htmlText = htmlText.replace(/Û/g,'&Ucirc;'); // 219 DB
    htmlText = htmlText.replace(/Ü/g,'&Uuml;'); // 220 DC
    htmlText = htmlText.replace(/Ý/g,'&Yacute;'); // 221 DD
    htmlText = htmlText.replace(/Þ/g,'&THORN;'); // 222 DE
    htmlText = htmlText.replace(/ß/g,'&szlig;'); // 223 DF
    htmlText = htmlText.replace(/à/g,'&aacute;'); // 224 E0
    htmlText = htmlText.replace(/á/g,'&aacute;'); // 225 E1
    htmlText = htmlText.replace(/â/g,'&acirc;'); // 226 E2
    htmlText = htmlText.replace(/ã/g,'&atilde;'); // 227 E3
    htmlText = htmlText.replace(/ä/g,'&auml;'); // 228 E4
    htmlText = htmlText.replace(/å/g,'&aring;'); // 229 E5
    htmlText = htmlText.replace(/æ/g,'&aelig;'); // 230 E6
    htmlText = htmlText.replace(/ç/g,'&ccedil;'); // 231 E7
    htmlText = htmlText.replace(/è/g,'&egrave;'); // 232 E8
    htmlText = htmlText.replace(/é/g,'&eacute;'); // 233 E9
    htmlText = htmlText.replace(/ê/g,'&ecirc;'); // 234 EA
    htmlText = htmlText.replace(/ë/g,'&euml;'); // 235 EB
    htmlText = htmlText.replace(/ì/g,'&igrave;'); // 236 EC
    htmlText = htmlText.replace(/í/g,'&iacute;'); // 237 ED
    htmlText = htmlText.replace(/î/g,'&icirc;'); // 238 EE
    htmlText = htmlText.replace(/ï/g,'&iuml;'); // 239 EF
    htmlText = htmlText.replace(/ð/g,'&eth;'); // 240 F0
    htmlText = htmlText.replace(/ñ/g,'&ntilde;'); // 241 F1
    htmlText = htmlText.replace(/ò/g,'&ograve;'); // 242 F2
    htmlText = htmlText.replace(/ó/g,'&oacute;'); // 243 F3
    htmlText = htmlText.replace(/ô/g,'&ocirc;'); // 244 F4
    htmlText = htmlText.replace(/õ/g,'&otilde;'); // 245 F5
    htmlText = htmlText.replace(/ö/g,'&ouml;'); // 246 F6
    htmlText = htmlText.replace(/÷/g,'&divide;'); // 247 F7
    htmlText = htmlText.replace(/ø/g,'&oslash;'); // 248 F8
    htmlText = htmlText.replace(/ù/g,'&ugrave;'); // 249 F9
    htmlText = htmlText.replace(/ú/g,'&uacute;'); // 250 FA
    htmlText = htmlText.replace(/û/g,'&ucirc;'); // 251 FB
    htmlText = htmlText.replace(/ü/g,'&uuml;'); // 252 FC
    htmlText = htmlText.replace(/ý/g,'&yacute;'); // 253 FD
    htmlText = htmlText.replace(/þ/g,'&thorn;'); // 254 FE
    htmlText = htmlText.replace(/ÿ/g,'&yuml;'); // 255 FF
    htmlText = htmlText.replace(/\n/g,'<br/>');

    return includeContainer ? `<${containerTag}>${htmlText}</${containerTag}>` : htmlText;
  },

  convertTZ(date : any, tzString : string='Asia/Singapore') {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
  },

  getUTCDateString(date : any) {
    let dtStr = '';
    if(this.isNotNullAndUndefined(date)) {
      dtStr = date.toISOString();
      dtStr = dtStr.replace('T', ' ').replace('Z', '');
    }
    return dtStr;
  },

  convertRDGOperatorToDirectUsOperator(rdgOperator : string) : string {
    let optMap : any = {
      contains: '_icontains',
      notcontains: '_nicontains',
      eq: '_eq',
      neq: '_neq',
      empty: '_empty',
      notempty: '_nempty',
      startswith: '_istarts_with',
      endswith: '_iends_with',
      gt: '_gt',
      gte: '_gte',
      lt: '_lt',
      lte: '_lte',
      inrange: '_between',
      notinrange: '_nbetween',
      inlist: '_in',
      notinlist: '_nin',
      after: '_gt',
      afteroron: '_gte',
      before: '_lt',
      beforeoron: '_lte'
    };
    
    return optMap[rdgOperator.toLowerCase()];
  },

  convertRDGFilterValueToDirectUsFilterValue(value : any, type : string) {
    if(this.isNotNullAndUndefined(value)) {
      if(typeof value === 'object') {
        if(type === 'date') {
          let start = this.stringHasValue(value.start) ? this.getUTCDateString((new Date(value.start))) : null;
          let end = this.stringHasValue(value.end) ? this.getUTCDateString((new Date(value.end))) : null;
          return [start, end];
        }
        else
          return [value.start, value.end];
      }
      else {
        if(type === 'date') {
          return this.stringHasValue(value) ? this.getUTCDateString((new Date(value))) : null;
        }
        else
          return value;
      }
    }
    else {
      return value;
    }
  },

  checkRDGFilterValueIsValid(value : any) : boolean {
    if(!this.isNotNullAndUndefined(value)) {
      return false;
    }

    if(typeof value === 'object') {
      return this.stringHasValue(value.start) && this.stringHasValue(value.end);
    }
    
    if(typeof value === 'string'){
      return this.stringHasValue(value);
    }

    return true;
  },

  populateSearchFilter(filters : any) : any {
    let populatedFilter : any = null;

    if(this.isNotNullAndUndefined(filters)) {
      populatedFilter = {};
      if(Array.isArray(filters)) {
        filters.forEach(item => {
          if(this.checkRDGFilterValueIsValid(item.value)) {
            let operator = this.convertRDGOperatorToDirectUsOperator(item.operator);
            if(this.isNotNullAndUndefined(operator)) {
              populatedFilter[item.name] = {};
              populatedFilter[item.name][operator] = this.convertRDGFilterValueToDirectUsFilterValue(item.value, item.type);
            }
          }
        });
      }
      else {
        if(this.checkRDGFilterValueIsValid(filters.value)) {
          let operator = this.convertRDGOperatorToDirectUsOperator(filters.operator);
          if(this.isNotNullAndUndefined(operator)) {
            populatedFilter[filters.name] = {};
            populatedFilter[filters.name][operator] = this.convertRDGFilterValueToDirectUsFilterValue(filters.value, filters.type);
          }
        }
      }
    }
    return populatedFilter;
  },

  populateLoggingDetail(error : any, data : any, type : string ='error') {
    return (
      {
        app_id: process.env.APP_ID,
        type: type,
        message: this.getErrorMsg(error),
        stack_trace: this.getStackTrace(error),
        source: this.carefullyGetStringValue(window, 'location.href'.split('.'), ''),
        user: null, // will be set in reducer if any
        data: {
          browser: browserName, 
          browserVersion: fullBrowserVersion, 
          os: osName, 
          osVersion: osVersion,
          ua: getUA,
          state: data,
          date_raised: new Date()
        }
      }
    );
  },

  getLogicalPath(siteConfig : object) : string {
    let pathName = '/';
    if(typeof window !== 'undefined') {
      pathName = window.location.pathname;
      let langs = this.carefullyGetArrayValue(siteConfig, 'allSitePlugin.nodes.0.pluginOptions.languages'.split('.'), []);
      if(this.arrayHasItem(langs)) {
        for(let idx=0; idx<langs.length; idx++){
          if(pathName.includes(`/${langs[idx]}`)){
            pathName = pathName.replace(`/${langs[idx]}`, '');
            break;
          }
        }
      }

      if(pathName[pathName.length - 1] === '/' && pathName.length > 1) {
        pathName = pathName.substring(0, pathName.length - 1);
      }
    }

    return pathName;
  },

  getSupportedLanguages(siteConfig : object) : any[] {
    let langs = [];
    if(typeof window !== 'undefined') {
      langs = this.carefullyGetArrayValue(siteConfig, 'allSitePlugin.nodes.0.pluginOptions.languages'.split('.'), []);
    }

    return langs;
  },

  makeUrl(baseUrl : string, subPath : string) : string {
    let fullUrl = '';
    if(this.stringHasValue(baseUrl)) {
      let partialUrl = helper.stringHasValue(subPath) ? subPath : '';
      if(baseUrl[baseUrl.length - 1] !== '/')
        fullUrl = `${baseUrl}/${partialUrl}`;
      else
        fullUrl = `${baseUrl}${partialUrl}`;
    }

    return fullUrl;
  },

  calculateTax(taxRate : any, taxConfig: any, principleAmt : number) : any {
    /* 
      system configured tax rate
      taxRate = {
        "gst": {
          "rate": 8,
          "new_rate": {
            "rate": null,
            "effective_on": null
          }
        }
      }

      trans type tax config
      taxConfig = {
        "gst": {
          "config": "none",
          "chargable": 100
        }
      }
    */
  
    // Get the GST rate
    let rate = this.carefullyGetIntValue(taxRate, ['gst', 'rate'], 0);
    if(this.isNotNullAndUndefined(taxRate?.gst?.new_rate?.rate) && this.isNotNullAndUndefined(taxRate?.gst?.new_rate?.effective_on)) {
      let effectiveOn = taxRate.gst.new_rate.effective_on;
      let now = new Date();
      if(typeof effectiveOn === 'string') {
        effectiveOn = new Date(effectiveOn);
      }

      if(now.getTime() >= effectiveOn.getTime()) {
        rate = taxRate.gst.new_rate.rate;
      }
    }

    let tax = {
      gst: {
        config: this.carefullyGetStringValue(taxConfig, ['gst','config'], 'none'),
        rate: rate,
        chargable: this.carefullyGetIntValue(taxConfig, ['gst', 'chargable'], 0),
        gst_amount: 0
      },
      principle_amount: principleAmt,
      payable_amount: principleAmt
    };

    // Note: chargable is reserved for future use

    if(tax.gst.config !== 'none') {
      switch (tax.gst.config) {
        case 'with':
          tax.gst.gst_amount = Math.round(principleAmt * (rate / 100));
          tax.payable_amount = Math.round(tax.principle_amount + tax.gst.gst_amount);
          break;
      
        case 'absorb':
          tax.principle_amount = Math.round(tax.payable_amount / (1 + (rate / 100)));
          tax.gst.gst_amount = Math.round(tax.payable_amount - tax.principle_amount);
          break;

      }
    }
    else {
      tax.gst.rate = 0; // set to 0 as config is 'none'
      tax.gst.chargable = 0; // set to 0 as config is 'none'
    }

    return tax;
  },

  camelCase(str : string) : string {
    // Using replace method with regEx
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index == 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  },

  capitalizeEachWord(str : string) : string{
    const str_arr = str.split(' ')

    for(let i = 0; i < str_arr.length; i++){
        str_arr[i] = str_arr[i][0].toUpperCase() + str_arr[i].slice(1)
    }
    return str_arr.join(' ')
  },

  calculateAge(dob : Date, compareTo : Date = new Date()) : number {
    var diff_ms = compareTo.getTime() - dob.getTime();
    var age_dt = new Date(diff_ms); 
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  },

  countPattern(str : string, pattern : string) : number {
    const regex = new RegExp(pattern, 'g');
    let match;
    let count = 0;
    while ((match = regex.exec(str)) !== null) {
      count++;
    }
    return count;
  }
  
}