import { createDirectus, rest, readItem, readItems, createItem,
  createItems, updateItem, updateItems, deleteItem, deleteItems,
  authentication, passwordRequest, passwordReset,
  inviteUser, acceptUserInvite, readMe, updateMe, 
  readUsers, createUser, updateUser, deleteUser,
  generateTwoFactorSecret, enableTwoFactor, disableTwoFactor,
  deleteFile, updateFile, uploadFiles, aggregate
} from '@directus/sdk';
import { helper } from '../lib/helper';
import { apiStorage } from './apiStorage';

const directusClient = createDirectus(process.env.API_URL as string).with(rest()).with(authentication('json', {autoRefresh: false, storage: apiStorage()}));

export function syncLog(logs : any[]) {
  return directusClient.request(createItems('app_log', logs));
}

export function authenticate(email : string, password : string, otp? : string) {
  return directusClient.login(email, password, {otp: otp});
}

export function refresh() {
  return directusClient.refresh()
}

export function logout() {
  return directusClient.logout();
}

export function authViaOtp(email : string, otp : string, purpose : string) {
  return directusClient.request(() => ({
    path: `/auth/authrequest/consumeotp`,
    body: JSON.stringify({user_id: email, otp: otp, purpose: purpose}),
    method: 'POST',
  }));
}

export function appSettingGet(lang : string) {
  let url = helper.stringHasValue(lang) ? `system?app=${process.env.APP_ID}&lang=${lang}` : `system?app=${process.env.APP_ID}`;
       
  return Promise.all([
    directusClient.request(() => ({
      path: url,
      method: 'GET',
    })),
    directusClient.request(() => ({
      path: `auth`,
      method: 'GET',
    }))
  ]);
}

export function webPushReg(regInfo : any) {
  return directusClient.request(() => ({
    path: `/webpush/reg`,
    body: JSON.stringify(regInfo),
    method: 'POST',
  }));
}

export function userPasswordResetRequest(email : string, url : string) {
  return directusClient.request(passwordRequest(email, url));
}

export function userPasswordReset(token : string, newPwd : string) {
  return directusClient.request(passwordReset(token, newPwd));
}

export function userInvite(email : string, role : string, inviteURL : string) {
  return directusClient.request(inviteUser(email, role, inviteURL));
}

export function userAcceptInvite(token : string, password : string) {
  return directusClient.request(acceptUserInvite(token, password));
}

export function userProfile() {
  return directusClient.request(readMe());
}

export function userProfileUpdate(data : any) {
  return directusClient.request(updateMe(data));
}

export function tfaGenerate(currentPwd : string) {
  return directusClient.request(generateTwoFactorSecret(currentPwd));
}

export function tfaEnable(secret : string, otp : string) {
  return directusClient.request(enableTwoFactor(secret, otp));
}

export function tfaDisable(otp : string) {
  return directusClient.request(disableTwoFactor(otp));
}

export function fileNew(file : any) {
  const form = new FormData();
  form.append("file", file);
  return directusClient.request(uploadFiles(form));
}

export function fileDelete(fileId : string) {
  return directusClient.request(deleteFile(fileId));
}

export function loginPersistencyConfigSet(setting : any) {
  return directusClient.request(() => ({
    path: `/system/loginpersistencyconfig`,
    body: JSON.stringify(setting),
    method: 'POST',
  }));
}

export function loginSecurityConfigSet(setting : any) {
  return directusClient.request(() => ({
    path: `/system/loginsecurityconfig`,
    body: JSON.stringify(setting),
    method: 'POST',
  }));
}

export function userMgtConfigSet(setting : any) {
  return directusClient.request(() => ({
    path: `/system/usermgtconfig`,
    body: JSON.stringify(setting),
    method: 'POST',
  }));
}

export function loggingConfigSet(setting : any) {
  return directusClient.request(() => ({
    path: `/system/loggingconfig`,
    body: JSON.stringify(setting),
    method: 'POST',
  }));
}

export function codeGetList(query : any) {
  return Promise.all([
    directusClient.request(aggregate('app_code', {
      query: {
        filter: query.filter
      },
      aggregate: {
        count: '*',
      },
      // groupBy: ['type'],
    })),
    directusClient.request(readItems('app_code', query))
  ]);
}

export function codeSet(data : any) {
  const newData : any = {
    category: data.category,
    code: data.code,
    text: data.text_en_US,
    sort: data.sort,
    status: data.status
  }
  if(!helper.stringHasValue(data.id)) // Only for create code
    newData.application = data.application;

  if(!helper.stringHasValue(data.id))
    return directusClient.request(createItem('app_code', newData));
  else
    return directusClient.request(updateItem('app_code', data.id, newData));
}

export function codeTranslationSet(data : any, lang : string) {
  let codeTransId = 0;
  const newData : any = {
    languages_code: lang,
    text: data[`text_${lang.replace('-', '_')}`]
  }

  // Get the translation id
  if(helper.arrayHasItem(data.translations)) {
    let transItem = data.translations.find((item : any) => item.languages_code === lang);
    if(helper.isNotNullAndUndefined(transItem)) {
      codeTransId = transItem.id;
    }
  }

  // If it is a new record, include the app_code_id field
  if(codeTransId <= 0) {
    newData.app_code_id = data.id;
  }
  
  // console.log('codeTranslationSet: ', newData);
  if(codeTransId <= 0)
    return directusClient.request(createItem('app_code_translations', newData));
  else
    return directusClient.request(updateItem('app_code_translations', codeTransId, newData));
}

export function codeDelete(id : any) {
  return directusClient.request(deleteItem('app_code', id));
}

export function codeTranslationDelete(ids : any[]) {
  return directusClient.request(deleteItems('app_code_translations', ids));
}

export function roleSet(role : any) {
  return directusClient.request(() => ({
    path: `/system/rolesetting`,
    body: JSON.stringify(role),
    method: 'POST',
  }));
}

export function menuGetList() {
  const query = {
    fields: ['id', 'status', 'sort', 'name', 'tooltip', 'route', 'param', 'icon', 'is_container',
    'is_public', 'parent_menu', 'date_effective', 'date_expired', 'role.id', 'role.directus_roles_id',
    'translations.id', 'translations.languages_code', 'translations.name', 'translations.tooltip'],
    filter: {"application": { "_eq": process.env.APP_ID }},
    limit: -1
  };

  return directusClient.request(readItems('app_menu', query));
}

export function menuSet(data : any) {
  return directusClient.request(() => ({
    path: `/system/menu`,
    body: JSON.stringify(data),
    method: 'POST',
  }));
}

export function menuDelete(menuId : string) {
  return directusClient.request(() => ({
    path: `/system/menu/${menuId}`,
    method: 'DELETE',
  }));
}

export function screenGetList(query : any) {
  return Promise.all([
    directusClient.request(aggregate('app_screen', {
      query: {
        filter: query.filter
      },
      aggregate: {
        count: '*',
      },
      // groupBy: ['type'],
    })),
    directusClient.request(readItems('app_screen', query))
  ]);
}

export function screenSet(data : any) {
  return directusClient.request(() => ({
    path: `/system/screen`,
    body: JSON.stringify(data),
    method: 'POST',
  }));
}

export function screenDelete(screenId : string) {
  return directusClient.request(() => ({
    path: `/system/screen/${screenId}`,
    method: 'DELETE',
  }));
}

export function userGetList(query : any) {
  return Promise.all([
    directusClient.request(aggregate('directus_users', {
      query: {
        filter: query.filter
      },
      aggregate: {
        count: '*',
      },
      // groupBy: ['type'],
    })),
    directusClient.request(readUsers(query))
  ]);
}

export function userCreate(data : any) {
  return directusClient.request(createUser(data));
}

export function userUpdate(data : any) {
  let id = data.id;
  let userDetail = helper.deepCopy(data);
  // Remove the fields that not allow to change.
  delete userDetail.id;
  delete userDetail.password;
  delete userDetail.auth_data;
  delete userDetail.external_identifier;
  delete userDetail.last_access;
  delete userDetail.last_page;
  delete userDetail.provider;
  delete userDetail.tfa_secret;
  delete userDetail.token;
  delete userDetail.newpassword;
  delete userDetail.confirmpassword;

  return directusClient.request(updateUser(id, userDetail));
}

export function userDelete(id : string) {
  return directusClient.request(deleteUser(id));
}

export function userDisableTFA(id : string) {
  return directusClient.request(() => ({
    path: `users/${id}/tfa/disable`,
    body: JSON.stringify({}),
    method: 'POST',
  }));
}

export function appContentGet(appId : string, lang : string) {
  let url = helper.stringHasValue(lang) ? `content?app=${appId}&lang=${lang}` : `content?app=${appId}`;
  
  return directusClient.request(() => ({
    path: url,
    method: 'GET',
  }));
}

export function subscriptionPlanGetList(lang : string) {
  let url = helper.stringHasValue(lang) ? `/subscription?app=${process.env.MOBILE_APP_ID}&lang=${lang}` : `subscription?app=${process.env.MOBILE_APP_ID}`;
  return directusClient.request(() => ({
    path: url,
    method: 'GET'
  }));
}

export function userSettingGetList(userId: string) {
  return directusClient.request(readItems('user_setting', {
    fields: ['id', 'status', 'user', 'date_created', 'date_updated', 'name', 'identifier', 'value'],
    filter: {
      "user": { "_eq": userId },
      "status": { "_eq": "published" }
    },
    sort: ['identifier'],
    limit: -1
  }));
}

export function userLastSub(userId: string) {
  return directusClient.request(readItems('subscription', {
    fields: ['id', 'status', 'user', 'date_created', 'date_updated', 'plan.*', 'plan_param', 'date_effective', 'date_expired'],
    filter: {
      "user": { "_eq": userId },
      "status": { "_eq": "active" }
    },
    sort: ['-date_expired'],
    limit: -1
  }));
}

export function subscriptionSubmit(data : any) : Promise<any> {
  return directusClient.request(() => ({
    path: 'subscription',
    body: JSON.stringify(data),
    method: 'POST'
  }));
}

export function paymentGet(id : string) {
  return directusClient.request(readItem('payment', id));
}