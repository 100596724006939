import type { AuthenticationStorage, AuthenticationData } from "@directus/sdk";
import queryKeys from "./queryKeys";

/**
 * Simple memory storage implementation
 *
 * @returns AuthenticationStorage
 */
export const apiStorage = () => {
	let store: AuthenticationData | null = null;

	return {
		get: async () => {
      const defaultState = { access_token: null, refresh_token: null, expires: null, expires_at: null };
      
      const savedStateString = localStorage.getItem(queryKeys.client.auth);
      const state = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : defaultState;

      return { access_token: state.access_token, refresh_token: state.refresh_token, expires: state.expires, expires_at: state.expires_at };
    },
		set: async (value: AuthenticationData | null) => {
      // let isReset = value?.access_token === null && value.refresh_token === null && value.expires === null && value.expires_at === null;
      const defaultState = {
        access_token: null,
        expires: null,
        refresh_token: null,
        expires_at: null,
        id: null,
        role: null,
        app_access: 0,
        admin_access: 0,
        iat: null,
        exp: null,
        iss: "",
        rememberMe: false, 
        heartBeat: new Date()
      };
      
      const savedStateString = localStorage.getItem(queryKeys.client.auth);
      const state = savedStateString !== null && savedStateString !== undefined ? JSON.parse(savedStateString) : defaultState;

      // if(!isReset) {
        state.access_token = value?.access_token;
        state.refresh_token = value?.refresh_token;
        state.expires = value?.expires;
        state.expires_at = value?.expires_at;

        localStorage.setItem(queryKeys.client.auth, JSON.stringify(state));
      // }
      // else {
      //   defaultState.rememberMe = state.rememberMe; // Do not reset the rememberMe field
      //   localStorage.setItem(queryKeys.client.auth, JSON.stringify(defaultState));
      // }
		},
	} as AuthenticationStorage;
};
